/* eslint-disable no-mixed-spaces-and-tabs */
import { Stack, Typography } from "@mui/material";
import { TaskPublicComplaint } from "types/graphql/queries/detailPublicComplaint";

interface DetailPontuationProps {
	title: string;
	task: TaskPublicComplaint;
	isNotConcluded?: boolean;
}

export function TaskWebReportDetail({
	title,
	task,
	isNotConcluded,
}: DetailPontuationProps) {
	return (
		<Stack minHeight={70}>
			<Typography variant="subtitle2" fontWeight={500} fontSize="0.9rem">
				{title}
			</Typography>
			<Typography variant="caption" fontSize="0.8rem">
				<Typography variant="caption" fontSize="0.8rem">
					{task.answer ? (
						<>
							<Typography fontSize="0.8rem" component="span">
								{task.answer}
							</Typography>
							<br />
							{isNotConcluded
								? "Em diligência"
								: `${task.concluded_at ? "Concluida em : " + task.concluded_at : ""}`}
						</>
					) : (
						<>
							{isNotConcluded
								? "Em diligência"
								: `${task.concluded_at ? "Concluida em : " + task.concluded_at : ""}`}
						</>
					)}
				</Typography>
			</Typography>
		</Stack>
	);
}
