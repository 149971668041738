import { useState } from "react";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	timelineItemClasses,
	TimelineSeparator,
} from "@mui/lab";
import { Stack } from "@mui/material";
import { Box, Typography, Backdrop, Link } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import CheckIcon from "@mui/icons-material/Check";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { DetailPublicComplaint } from "types/graphql/queries/detailPublicComplaint";
import { DETAIL_PUBLIC_COMPLAINT } from "graphql/queries/detailPublicComplaint";
import { useParams, useNavigate } from "react-router-dom";
import { TaskWebReportDetail } from "./TaskWebReportDetail";
import IconButton from "components/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pdf from "assets/img/pdf.png";
import ImgList from "components/ImgList";

export function WebReportDetail() {
	const subdomain = window.location.hostname.split(".")[0];
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const navigate = useNavigate();
	const params = useParams();
	const protocolo = params.protocolo;

	const { data: dataComplaint, loading } = useQuery<DetailPublicComplaint>(
		DETAIL_PUBLIC_COMPLAINT,
		{
			variables: {
				code: String(protocolo),
			},
			fetchPolicy: "network-only",
		}
	);

	const isSuspenseOrCanceled =
		dataComplaint?.detailPublicComplaint.situation === "Suspenso" ||
		dataComplaint?.detailPublicComplaint.situation === "Cancelado";

	const imgArr = dataComplaint?.detailPublicComplaint.attachments.map(
		(item) => item.url
	);

	const fiscalDescription =
		dataComplaint?.detailPublicComplaint.tasks[1]?.answer ===
			"Recusar ocorrência" &&
			dataComplaint?.detailPublicComplaint.tasks[0]?.name === "Finalizado"
			? dataComplaint?.detailPublicComplaint.tasks[0]?.answer
			: null;

	const isLoading = loading || loadingDetailCompany;

	return (
		<>
			<IconButton
				onClick={() => navigate(-1)}
				sx={{
					display: ["block", "block", "none"],
					position: "fixed",
					top: "1rem",
					left: "1rem",
					zIndex: 100,
				}}
				color="inherit"
			>
				<ArrowBackIcon />
			</IconButton>
			{isLoading && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{data && dataComplaint && (
				<Box minHeight="100vh" display="flex" minWidth="100%">
					<Box
						display={["none", "none", "block"]}
						flex={1}
						sx={{
							backgroundColor: "red",
						}}
					>
						<Box
							sx={{
								backgroundImage: `url(${data.detailCompany.cover})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundAttachment: "fixed",
								width: "100%",
								height: "100%",
							}}
						/>
					</Box>

					<Stack
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: "1",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Stack padding="1rem" flex={1} spacing={2}>
							<Box
								textAlign="center"
								sx={{
									backgroundImage: `url(${data.detailCompany.logo})`,
									backgroundSize: "contain",
									backgroundPosition: "center",
									backgroundRepeat: "no-repeat",
									width: ["250px", "350px"],
								}}
								height="100px"
							/>
						</Stack>
						<Stack
							padding="1rem"
							sx={{ marginTop: "10px", alignItems: "center" }}
							flex={1}
							spacing={2}
							width="100%"
							paddingX={4}
						>
							<Typography fontWeight={700} fontSize="1.8rem">
								WebDenúncia
							</Typography>
							<Stack
								direction={["column", "row", "row"]}
								justifyContent={["space-between", "space-between", "center"]}
								alignItems="center"
								width="100%"
							>
								<Box marginRight={[0, 0, 2]}>
									<Typography variant="caption" fontSize="0.9rem">
										Protocolo
									</Typography>
									<Typography
										variant="body1"
										fontWeight={500}
										fontSize="1.6rem"
										sx={{ color: "#1976D2" }}
									>
										{protocolo}
									</Typography>
								</Box>

								<Box marginLeft={[0, 0, 2]}>
									<Typography variant="caption" fontSize="0.9rem">
										Situação
									</Typography>
									<Typography variant="body1" fontSize="1.4rem">
										{dataComplaint?.detailPublicComplaint.situation}
									</Typography>
								</Box>
							</Stack>
						</Stack>

						<Stack padding={1} width="100%" paddingX={4}>
							{dataComplaint?.detailPublicComplaint.district && (
								<Box
									sx={{
										marginY: "1.5rem",
										alignItems: "start",
										textAlign: "left",
									}}
								>
									<Typography
										variant="subtitle2"
										fontWeight={700}
										fontSize="0.9rem"
									>
										Endereço
									</Typography>
									<Typography variant="body1" fontSize="1.1rem">
										{dataComplaint?.detailPublicComplaint.address
											? dataComplaint?.detailPublicComplaint.address + ","
											: null}{" "}
										{dataComplaint?.detailPublicComplaint.number
											? dataComplaint?.detailPublicComplaint.number + ","
											: null}{" "}
										{dataComplaint?.detailPublicComplaint.district.name + ","}{" "}
										{dataComplaint?.detailPublicComplaint.reference}{" "}
										{dataComplaint?.detailPublicComplaint.zipcode
											? ", " + dataComplaint?.detailPublicComplaint.zipcode
											: null}
									</Typography>
								</Box>
							)}

							<Box
								sx={{
									marginBottom: "1rem",
									textAlign: "left",
								}}
							>
								<Typography
									variant="subtitle2"
									fontWeight={700}
									fontSize="0.9rem"
								>
									Descrição
								</Typography>
								<Typography
									variant="body1"
									fontSize="1.1rem"
									sx={{
										wordBreak: "break-word",
									}}
								>
									{dataComplaint?.detailPublicComplaint.description}
								</Typography>
							</Box>

							{dataComplaint?.detailPublicComplaint
								.complaint_refuse_justification && (
									<Box
										sx={{
											marginBottom: "1rem",
											textAlign: "left",
										}}
									>
										<Typography
											variant="subtitle2"
											fontWeight={700}
											fontSize="0.9rem"
										>
											Justificativa da recusa
										</Typography>
										<Typography
											variant="body1"
											fontSize="1.1rem"
											sx={{
												wordBreak: "break-word",
											}}
										>
											{
												dataComplaint?.detailPublicComplaint
													.complaint_refuse_justification
											}
										</Typography>
									</Box>
								)}

							{dataComplaint?.detailPublicComplaint.tasks.length === 0 ? (
								<Box marginY={2}>
									<Typography>Denúncia não foi avaliada</Typography>
								</Box>
							) : (
								<Timeline
									sx={{
										[`& .${timelineItemClasses.root}:before`]: {
											flex: 0,
											padding: 0,
										},
										padding: 0,
									}}
								>
									{dataComplaint?.detailPublicComplaint.tasks.map(
										(item, index) => {
											const isLastIndex =
												index ===
												dataComplaint?.detailPublicComplaint.tasks.length - 1;
											const isFirstIndex = index === 0;

											const isNotConcluded =
												isFirstIndex &&
												dataComplaint?.detailPublicComplaint.situation !==
												"Concluido" &&
												!isSuspenseOrCanceled;
											return (
												<>
													{item.type !== "Pontuação" && (
														<TimelineItem
															key={item.id}
															sx={{
																backgroundColor:
																	index === 0 && isNotConcluded && "#FAFAD2",
																paddingX: "30px",
																paddingY: "10px",
															}}
														>
															<TimelineSeparator>
																<TimelineDot color="inherit">
																	{index === 0 && isNotConcluded ? (
																		<AccessAlarmIcon />
																	) : (
																		<CheckIcon />
																	)}
																</TimelineDot>
																{!isLastIndex && <TimelineConnector />}
															</TimelineSeparator>
															<TimelineContent>
																<TaskWebReportDetail
																	task={item}
																	title={item.name}
																	isNotConcluded={isNotConcluded}
																/>
															</TimelineContent>
														</TimelineItem>
													)}
												</>
											);
										}
									)}
								</Timeline>
							)}

							{fiscalDescription && (
								<Box textAlign="left" paddingY={2}>
									<Typography variant="subtitle1">Parecer:</Typography>
									<Typography>{fiscalDescription}</Typography>
								</Box>
							)}

							{imgArr?.length > 0 && (
								<Box textAlign="left" marginTop={2}>
									<Typography variant="subtitle1">Anexos:</Typography>
								</Box>
							)}
							<Stack
								sx={{
									display: "flex",
									justifyContent: "space-around",
									flexDirection: "row",
									flexWrap: "wrap",
								}}
							>
								<>
									{dataComplaint &&
										imgArr.map((item, index) => {
											const imageUrl = item;
											if (item.endsWith(".pdf")) {
												return (
													<Link href={imageUrl} key={index}>
														<Box
															sx={{
																backgroundImage: `url(${pdf})`,
																backgroundSize: "contain",
																backgroundPosition: "center",
																backgroundRepeat: "no-repeat",
																width: ["100px", "100px"],
																marginY: 5,
																cursor: "pointer",
																height: "100px",
															}}
														/>
													</Link>
												);
											}
										})}
									{dataComplaint &&
										dataComplaint.detailPublicComplaint.attachments.length >
										0 && (
											<ImgList
												itemsData={
													dataComplaint.detailPublicComplaint.attachments
												}
											/>
										)}
								</>
							</Stack>

							<Stack display={["none", "none", "block"]}>
								<Link
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										border: "none",
										cursor: "pointer",
									}}
									onClick={() => navigate(-1)}
								>
									VOLTAR
								</Link>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			)}
		</>
	);
}
